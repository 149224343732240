/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { getAuth } from "firebase/auth";
import {
  get30ReportAccounts,
  getEmployees,
  getEmployeeFromUser,
  Employees,
  getAccountNumbers,
  getReportsFor30Percent,
  getReports,
  AccountNumbers,
  IReport,
  Employee,
  shouldUpdateCache,
  clearCache,
  uploadFiles,
} from "./services/backend.service";
import {
  Box,
  Text,
  Icon,
  Currency,
  PeriodName,
  Pill,
  Stack,
  AccountVisualization,
  LoginButton,
  Sparkline,
  Menu,
} from "./components";
import { css, keyframes } from "emotion";
const blink = keyframes`
  to {
    opacity: 1;
  }
`;
const loadingStyle = css`
  animation: ${blink} 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
  opacity: 0;
`;

const App: React.FC<{}> = () => {
  /** The Firebase (Google) User currently signed in */
  const [signedInUser, setSignedInUser] = useState<User | null>(
    getAuth().currentUser,
  );
  /** The employee data for the currently signed in user */
  const [signedInEmployee, setSignedInEmployee] = useState<
    Employee | undefined
  >(undefined);
  /** The currently viewed project number (ie. person) */
  const [projectNumber, setProjectNumber] = useState<number | undefined>(
    undefined,
  );
  /** The currently viewed report (ie. month) */
  const [selectedReportIndex, setSelectedReportIndex] = useState<number>(0);
  /** The name of the currently viewed account grouping */
  const [selectedAccountName, setSelectedAccountName] = useState<
    string | undefined
  >(undefined);

  const [employees, setEmployees] = useState<Employees | undefined>(undefined);
  const [accountNumbers, setAccountNumbers] = useState<
    AccountNumbers | undefined
  >(undefined);
  const [reports, setReports] = useState<Array<IReport | undefined>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employeeSelectOpen, setEmployeeSelectOpen] = useState<boolean>(false);
  const maxTotaltBalances: Record<string, number> = {
    "22": 312400,
    "21": 300080,
    "20": 293920,
  };
  const totalBalanceWarning = "Du har för mycket i din pool. Höj din lön!";

  const [view30Percent, setView30Percent] = useState(false);
  const [accountFor30Percent, setAccountFor30Percent] =
    useState<Record<string, number[]>>();

  const updateStoreState = async () => {
    if (signedInUser) {
      setAccountNumbers(await getAccountNumbers());
      setEmployees(await getEmployees());
      const employee = await getEmployeeFromUser(signedInUser);
      if (employee) {
        setSignedInEmployee(employee);
        setProjectNumber(employee.projectNumber);
      }
    }
  };

  const updateViewState = async () => {
    if (projectNumber && signedInUser) {
      const reports = view30Percent
        ? await getReportsFor30Percent()
        : await getReports(projectNumber);
      setReports(reports);
      setSelectedReportIndex(reports.length - 1);
      setSelectedAccountName(undefined);
    }
  };

  const refreshData = async () => {
    clearCache();
    await updateStoreState();
    await updateViewState();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.gstatic.com/charts/loader.js";
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    updateStoreState();
  }, [signedInUser]);
  useEffect(() => {
    updateViewState();
  }, [projectNumber, signedInUser, view30Percent]);

  useEffect(
    () =>
      onAuthStateChanged(getAuth(), (user) => {
        setSignedInUser(user);
      }),
    [],
  );

  useEffect(() => {
    (async () => {
      if (signedInEmployee) {
        if (await shouldUpdateCache(signedInEmployee)) {
          clearCache();
          updateStoreState();
        }
      }
    })();
  }, [signedInEmployee]);

  const currentReport = reports[selectedReportIndex];
  const currentEmployee = employees?.find(
    (employee) => employee.projectNumber === projectNumber,
  );

  // calculate amount spent on helth benefits this year
  const getCurrentYear = (report: IReport) =>
    parseInt(report.period.split("-")[0]);
  const currentReportYear = currentReport && getCurrentYear(currentReport);
  const reportsForCurrentYear = reports.filter(
    (report, index) =>
      report &&
      getCurrentYear(report) === currentReportYear &&
      index <= selectedReportIndex,
  );
  const healthBenefit = reportsForCurrentYear.reduce(
    (acc, report) =>
      report ? acc - (report.accounting_entries[7680] ?? 0) : acc,
    0,
  );
  const maxTotalBalance =
    currentReportYear && maxTotaltBalances[currentReportYear];
  const exceedingMaxTotalBalance =
    maxTotalBalance &&
    currentReport &&
    currentReport.balance_total &&
    currentReport.balance_total > maxTotalBalance;
  const lastReport = reports[reports.length - 1];
  const currentYear = lastReport && getCurrentYear(lastReport);

  return (
    <Box vertical height="100%" justifyContent="start" alignItems="stretch">
      {signedInEmployee && (
        <Box
          position="relative"
          justifyContent="space-between"
          padding={12}
          color="lighterGrey"
        >
          <Menu
            onRefreshData={async () => {
              setIsLoading(true);
              await refreshData();
              setIsLoading(false);
            }}
            onView30Percent={async () => {
              setView30Percent(true);
              setSelectedAccountName(undefined);
              const accounts = await get30ReportAccounts();
              setAccountFor30Percent(accounts);
            }}
            onUploadData={async (files) => {
              setIsLoading(true);
              await uploadFiles(files);
              await refreshData();
              setIsLoading(false);
            }}
          />
          {isLoading && (
            <div className={loadingStyle}>
              <Text style="semibold">Laddar…</Text>
            </div>
          )}

          {signedInEmployee && (
            <Text
              onClick={() => setEmployeeSelectOpen(!employeeSelectOpen)}
              uppercase
              style="semibold"
              color="darkGrey"
            >
              {signedInEmployee.name}
            </Text>
          )}
          {employeeSelectOpen && employees && employees.length > 0 && (
            <Box
              position="absolute"
              top="36px"
              right="12px"
              height="200px"
              overflow="auto"
              color="lighterGrey"
              padding={10}
              vertical
              zIndex="1"
            >
              {employees.map((employee) => (
                <Box
                  padding={8}
                  key={employee.projectNumber}
                  onClick={() => {
                    setProjectNumber(employee.projectNumber);
                    setEmployeeSelectOpen(false);
                  }}
                >
                  <Text style="semibold">{employee.name}</Text>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}

      {!signedInEmployee && (
        <Box height="100%" justifyContent="center">
          <Stack vertical>
            <Text style="header">Börja med att logga in!</Text>
            <LoginButton callback={setSignedInUser} />
          </Stack>
        </Box>
      )}

      {currentReport && accountNumbers && (
        <>
          <Box vertical padding={20}>
            <Stack alignItems="center" vertical spacing={30}>
              <Stack alignItems="center" spacing={20}>
                <Icon
                  disabled={selectedReportIndex < 1}
                  onClick={() =>
                    setSelectedReportIndex(selectedReportIndex - 1)
                  }
                >
                  arrow_left
                </Icon>
                <Text
                  align="center"
                  minWidth="100px"
                  uppercase
                  style="subheader"
                >
                  <PeriodName showYear={currentReportYear !== currentYear}>
                    {currentReport.period}
                  </PeriodName>
                </Text>
                <Icon
                  disabled={selectedReportIndex + 1 >= reports.length}
                  onClick={() =>
                    setSelectedReportIndex(selectedReportIndex + 1)
                  }
                >
                  arrow_right
                </Icon>
              </Stack>

              {view30Percent && signedInEmployee ? (
                <Stack spacing={6} alignItems="center">
                  <Text uppercase style="semibold" color="pink">
                    Neodevs 30%
                  </Text>
                  <Icon
                    size={18}
                    color="pinkText"
                    onClick={() => {
                      setView30Percent(false);
                      setProjectNumber(signedInEmployee.projectNumber);
                      setSelectedAccountName(undefined);
                    }}
                  >
                    highlight_off
                  </Icon>
                </Stack>
              ) : (
                currentEmployee &&
                signedInEmployee &&
                signedInEmployee.projectNumber !==
                  currentEmployee.projectNumber && (
                  <Stack spacing={6} alignItems="center">
                    <Text uppercase style="semibold" color="pink">
                      {currentEmployee.name}
                    </Text>
                    <Icon
                      size={18}
                      color="pinkText"
                      onClick={() =>
                        setProjectNumber(signedInEmployee.projectNumber)
                      }
                    >
                      highlight_off
                    </Icon>
                  </Stack>
                )
              )}

              {!view30Percent &&
                !selectedAccountName &&
                currentReport.project_no && (
                  <>
                    <Text
                      color={exceedingMaxTotalBalance ? "red" : "darkGrey"}
                      style="max"
                    >
                      <Currency>{currentReport.balance_total}</Currency>
                    </Text>
                    {exceedingMaxTotalBalance && (
                      <span
                        className={css`
                          margin-top: -30px;
                        `}
                      >
                        <Text inline>{totalBalanceWarning}</Text>
                      </span>
                    )}

                    {currentReport.balance_period && (
                      <Pill
                        color={
                          currentReport.balance_period < 0 ? "pink" : "green"
                        }
                      >
                        <Text style="header" color="white">
                          <Currency showSign>
                            {currentReport.balance_period}
                          </Currency>
                        </Text>
                      </Pill>
                    )}

                    <Box>
                      <Sparkline
                        reports={reports.filter(
                          (report): report is IReport => report !== undefined,
                        )}
                        currentPeriod={currentReport.period}
                      />
                    </Box>
                  </>
                )}
            </Stack>
          </Box>

          <AccountVisualization
            accountEntries={currentReport.accounting_entries}
            accountNumbers={accountNumbers}
            onShowAccountDetails={setSelectedAccountName}
            accountNameToShowDetailsFor={selectedAccountName}
            healthBenefit={healthBenefit}
            accountGrouping={view30Percent ? accountFor30Percent : undefined}
          />
        </>
      )}
    </Box>
  );
};

export default App;
