/* eslint-disable react/style-prop-object */
import React from "react";
import { css } from "emotion";
import { Color, Colors, TextColors } from "./Colors";
import { AccountNumbers, IReport } from "../services/backend.service";
import { Box } from "./Box.component";
import { Text } from "./Text.component";
import { Currency } from "./Currency.component";
import { Icon } from "./Icon.component";

interface IAccountVisualizationProps {
  accountNumbers: AccountNumbers;
  accountEntries: IReport["accounting_entries"];
  accountNameToShowDetailsFor?: string;
  color?: Color;
  onShowAccountDetails(name: string | undefined): void;
  healthBenefit: number;
  accountGrouping?: Record<string, number[]>;
}

interface IAccount {
  number: number;
  name: string;
  value: number;
  color?: Color;
}

interface IGroupedAccount {
  name: string;
  color: Color;
  value: number;
}

export const AccountVisualization: React.FC<IAccountVisualizationProps> = ({
  accountNumbers,
  accountEntries,
  onShowAccountDetails,
  accountNameToShowDetailsFor,
  healthBenefit,
  accountGrouping,
}) => {
  const dynamicAccountGroup: Record<number, string> = {};
  const dynamicGroups = !!accountGrouping;
  if (accountGrouping) {
    Object.keys(accountGrouping).forEach((accountGroupName) => {
      accountGrouping[accountGroupName].forEach((accountNumber) => {
        dynamicAccountGroup[accountNumber] = accountGroupName;
      });
    });
  }

  const accountGroups: Record<number, string> = accountGrouping
    ? dynamicAccountGroup
    : {
        // 3041: 'Inkomst',
        // Öresavrundning
        3740: "Övrigt",
        6212: "Mobiltelefon",
        7210: "Lön",
        7412: "Pension",
        // Arbetsgivaravgift
        7510: "Skatter & avgifter",
        // Arbetsgivaravgift (Corona)
        7511: "Skatter & avgifter",
        // Löneskatt
        7533: "Skatter & avgifter",
        // Sjukförsäkring
        7540: "Skatter & avgifter",
        // Milersättning
        7331: "Bil",
        // Skatt/försäkring bil
        5612: "Bil",
        // Reparationer
        5613: "Bil",
        // Leasing
        5615: "Bil",
        // Bensin
        7385: "Bil",
        7680: "Friskvård",
        7699: "Ej redovisade företagskorttransaktioner",
      };

  const accountGroupsLookup = Object.keys(accountGroups).reduce(
    (obj, accountNumber) => {
      const category =
        accountGroups[accountNumber as any as keyof typeof accountGroups];
      const numbers = obj[category] || [];
      obj[category] = [...numbers, Number(accountNumber)];
      return obj;
    },
    {} as Record<string, number[]>,
  );

  const groupColors: Record<string, Color> = {
    "Skatter & avgifter": "pink",
    Pension: "green",
    Lön: "blue",
    Mobiltelefon: "white",
    Övrigt: "lightGrey",
    Bil: "darkGrey",
    Friskvård: "himmelsblau",
    "Ej redovisade företagskorttransaktioner": "orange",
    "VD och ledning": "blue",
    "Övriga kostnader": "lightPink",
    "Utveckling/Utbildning": "pink",
    "Resor/Personal/Rekrytering": "green",
    "Marknadsföring/Försäljning": "himmelsblau",
    Garantiersättning: "orange",
    "Kontorsdrift/Bolagsförvaltning": "darkGrey",
    Lokaler: "lightGrey",
  };

  const groupAccounts = (accounts: IGroupedAccount[], account: IAccount) => {
    const category =
      accountGroups[account.number as keyof typeof accountGroups] || "Övrigt";

    const sameAccount = accounts.find(
      (anAccount) => anAccount.name === category,
    );
    if (sameAccount) {
      sameAccount.value = sameAccount.value + account.value;
      return accounts;
    }
    return [
      ...accounts,
      {
        name: category,
        value: account.value,
        color: groupColors[category],
      },
    ];
  };

  const accountEntriesWithHealth: Record<number, number> = {
    7680: 0,
    ...accountEntries,
  };
  const includedAccountNumbers = Object.keys(accountGroups).map(
    (accountNumber) => Number(accountNumber),
  );
  const otherAccountNumbers = Object.keys(accountNumbers)
    .map((accountNumber) => Number(accountNumber))
    .filter(
      (accountNumber) =>
        accountNumber !== 7699 &&
        !includedAccountNumbers.includes(accountNumber),
    );

  const currentAccountNumbers = Object.keys(accountEntriesWithHealth).map(
    (account) => Number(account),
  );
  const currentAccountEntries = currentAccountNumbers.reduce(
    (entries, accountNumber) => {
      const value = accountEntriesWithHealth[accountNumber];
      return (value < -0.001 || accountNumber === 7680) &&
        accountNumber !== 3049
        ? [
            ...entries,
            {
              number: accountNumber,
              name: accountNumbers[accountNumber],
              value: Math.abs(value),
            },
          ]
        : entries;
    },
    [] as IAccount[],
  );

  if (accountNameToShowDetailsFor) {
    if (accountNameToShowDetailsFor === accountGroups[7680]) {
      const accounts: IGroupedAccount[] = [
        {
          value: healthBenefit,
          name: "Förbrukat under året",
          color: "himmelsblau",
        },
        { value: 5000 - healthBenefit, name: "Kvarvarande", color: "blue" },
      ];
      const total = 5000;
      return (
        <>
          <Box vertical alignItems="stretch" height="100%" padding={5}>
            {accounts.map((account: IGroupedAccount) => {
              const proportion = account.value / total;
              return (
                <div
                  key={account.name}
                  className={css`
                    height: ${((proportion * 100 + 10) / 110) * 100}%;
                    background-color: ${Colors[account.color]};
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    border-top: 1px solid #fff;
                  `}
                >
                  <Text
                    uppercase
                    style="semibold"
                    color={TextColors[account.color]}
                  >
                    {account.name}
                  </Text>
                  <Text
                    uppercase
                    style="semibold"
                    color={TextColors[account.color]}
                  >
                    <Currency>{account.value}</Currency>
                  </Text>
                </div>
              );
            })}
          </Box>
          <Box padding={10} justifyContent="center">
            <Icon onClick={() => onShowAccountDetails(undefined)}>cancel</Icon>
          </Box>
        </>
      );
    }
    const accounts =
      accountNameToShowDetailsFor === "Övrigt"
        ? otherAccountNumbers
            .map((number) =>
              currentAccountEntries.find(
                (account) => account.number === Number(number),
              ),
            )
            .filter((account): account is IAccount => account !== undefined)
        : accountGroupsLookup[accountNameToShowDetailsFor].map(
            (number): IAccount => ({
              number,
              name: accountNumbers[number],
              value:
                currentAccountEntries.find(
                  (account) => account.number === number,
                )?.value || 0,
            }),
          );
    const filteredAccounts = accounts
      .filter(
        (account) =>
          account.value > 0.99 ||
          (account.name !== undefined && account.name === accountGroups[7680]),
      )
      .sort((accountA, accountB) => accountA.value - accountB.value);

    const groupColor = groupColors[
      accountNameToShowDetailsFor as keyof typeof groupColors
    ] as Color;
    const total = filteredAccounts.reduce(
      (sum, account) => sum + account.value,
      0,
    );

    return (
      <>
        <Box vertical alignItems="stretch" height="100%" padding={5}>
          {filteredAccounts.map((account) => {
            const proportion = account.value / total;
            return (
              <div
                key={account.name}
                className={css`
                  height: ${((proportion * 100 + 10) / 110) * 100}%;
                  opacity: ${(proportion + 0.7) / 1.7};
                  background-color: ${Colors[groupColor]};
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 20px;
                  border-top: 1px solid #fff;
                `}
              >
                <Text uppercase style="semibold" color={TextColors[groupColor]}>
                  {account.name}
                </Text>
                <Text uppercase style="semibold" color={TextColors[groupColor]}>
                  <Currency>{account.value}</Currency>
                </Text>
              </div>
            );
          })}
        </Box>
        <Box padding={10} justifyContent="center">
          <Icon onClick={() => onShowAccountDetails(undefined)}>cancel</Icon>
        </Box>
      </>
    );
  }

  const groupedAccountEntries = currentAccountEntries
    .reduce(groupAccounts, [] as IGroupedAccount[])
    .filter(
      (account) =>
        (!dynamicGroups || account.name !== "Övrigt") &&
        (account.value > 0.99 || account.name === accountGroups[7680]),
    )
    .sort((accountA, accountB) => accountA.value - accountB.value);

  const total = groupedAccountEntries.reduce(
    (sum, account) => sum + account.value,
    0,
  );

  return (
    <Box vertical alignItems="stretch" height="50%" padding={5}>
      {groupedAccountEntries.map((account) => {
        const proportion = account.value / total;
        return (
          <div
            key={account.name}
            className={css`
              height: ${((proportion * 100 + 10) / 110) * 100}%;
              flex-shrink: 0;
              background-color: ${Colors[account.color]};
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 20px;
            `}
            onClick={() => onShowAccountDetails(account.name)}
          >
            <Text uppercase style="semibold" color={TextColors[account.color]}>
              {account.name}
            </Text>
            <Text uppercase style="semibold" color={TextColors[account.color]}>
              <Currency>{account.value}</Currency>
            </Text>
          </div>
        );
      })}
    </Box>
  );
};
